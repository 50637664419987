import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import Button from '../components/Button'
import Input from '../components/Input'

const Container = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
`

const Login = styled.section`
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  max-width: 600px;
  padding: 50px;
  border-radius: 4px;
  background: #ffff;
  border: 1px solid #fefefe;

  input {
  }
  button {
    margin-top: 20px;
  }
`

const COMMITTEE_NUMBERS = [
  '209829',
  '212014',
  '45616',
  '202142',
  '2205194',
  '46400',
  '43695',
  '46511',
  '228157',
  '300281',
]

// Brett Paull -  46400
// Mike Zupanc - 43695
// Sonya Fardell - 46511
// Wayne Smith - 228157
// Terry Carroll - 300281

export default ({ data }) => (
  <Layout header="dark">
    <Helmet>
      <title>SAFA Login - QLD Sports Aviators</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Container>
      {typeof window === 'object' &&
      localStorage.getItem('isLoggedIn') !== null ? (
        <div>{navigate('/members/')}</div>
      ) : (
        <Login>
          <h3>SAFA Committee Login</h3>
          <Input id="safaNumber" type="text" placeholder="SAFA Number" />
          <Button
            id="safaButton"
            onClick={() => {
              const number = parseInt(
                document.getElementById('safaNumber').value
              )
              console.log(number)
              if (
                typeof number === 'number' &&
                COMMITTEE_NUMBERS.includes(number.toString())
              ) {
                localStorage.setItem('isLoggedIn', number)

                navigate('/members/')
              } else {
                document.getElementById('safaButton').innerText =
                  'Error, Invalid Number!'
                setTimeout(() => {
                  document.getElementById('safaButton').innerText = 'Login'
                }, 3000)
              }
            }}
          >
            Login
          </Button>
        </Login>
      )}
    </Container>
  </Layout>
)

// export const query = graphql`
//   query LoginQuery { }
// `
